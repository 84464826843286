import request from '@/utils'

export const getList = async data => {
  return request({
    url: '/td/api/system/page',
    method: 'get',
    params: data
  })
}

export const saveAdd = async data => {
  return request({
    url: '/td/api/system',
    method: 'post',
    data
  })
}

export const adminLogin = async data => {
  return request({
    url: '/td/api/admin/login',
    method: 'post',
    data
  })
}

export const editAdd = async data => {
  return request({
    url: `/td/api/system/${data.id}`,
    method: 'put',
    data
  })
}

export const deleteItem = async data => {
  return request({
    url: `/td/api/system/${data.id}`,
    method: 'delete',
    params: data
  })
}

export const uploadImagwe = async data => {
  return request({
    url: '/td/api/photo/upload-file',
    method: 'post',
    data
  })
}

export const dragAfterid = async data => {
  return request({
    url: `/td/api/system/sort@2/${data.id}/${data.afterid}`,
    method: 'post',
    data
  })
}
