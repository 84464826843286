import axios from 'axios'
// env.NODE_ENV === 'development'
var request = axios.create({
  // 后台接口的基准地址
  baseURL: 'https://gfgadmin.dr5kr.live/',
  timeout: 15000
})

// 添加请求拦截器
request.interceptors.request.use(
  config => {
    return config
  },
  function(error) {
    //对相应错误做点什么
    return Promise.reject(error)
  }
)

//拦截器响应
request.interceptors.response.use(
  response => {
    return response
  },
  function(error) {
    //对相应错误做点什么
    return Promise.reject(error)
  }
)

export default request
